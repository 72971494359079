@import "partials/vars";
@import "partials/mixins";
//@import "partials/common.scss";

body {
    font-family: 'Muli', sans-serif !important;
}

h1 {
    font-size: 16px;
    font-weight: 700;
}

.page-content {
    max-width: 800px;
    padding: 10px;
    margin: 0 auto;
    min-height: calc(100vh - 90px - 272px);
}

fieldset{
    padding: 0;
    margin: 0;
    border: 0px;

    &.input {
        > div {
            margin: 1em 0;
            label {
                display: block;
                margin: 10px 0 0;
                svg[data-icon] {
                    color: $rhp-green;
                }
            }
            .input-wrap {
                margin: 10px 0;
            }
            input {
                padding: 10px;
                border: 1px solid #c7c7c7;
                background-color: #fff;
                outline: none !important;

                &::placeholder {
                    color: #707070;
                }
            }
            .MuiOutlinedInput-notchedOutline {
                border: none !important;
            }
            a {
                box-sizing: border-box;
                display: block;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 1.2em;
                width: 100%;
                //padding: 10px;
                padding: 0;
                margin: 0;
                text-align: left;
                color: $rhp-dark-gray;
                
                svg[data-icon] {
                    color: $rhp-green;
                }
                .label {
                    margin: 0 auto 0 4px;
                }
                .selection {

                    svg[data-icon="chevron-right"] {
                        float: right;
                        color: $rhp-gray;
                    }
                }

            }

            textarea {
                box-sizing: border-box;
                font: inherit;
                font-size: 16px;
                height: 200px;
                padding: 10px;
                //border: none;
                border-color: #c7c7c7;
                border-radius: 4px;
                outline: none !important;


                &::placeholder {
                    color: #b1b1b1;
                }
            }
        }
        #category {
            .selection {
                .value {
                    margin: 0 5px 0 0;
                }
            }
        }
        .error {
            svg[data-icon] {
                color: #AB070B;
            }

            #category {
                margin-bottom: 10px;
            }
        }
    }
    &.submit {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        //padding: 0 15px;
        p {
            display: inline-block;
            width: 50%;

            @media screen and (max-width: 487px) {
                width: 100%;
            }

            &:first-of-type {
                text-align: left;
                height: 1em;
                input[type=file]{
                    visibility: hidden;
                    width: 100%;
                    height: 0;
                }
            }
            &:last-of-type {
                text-align: right;
            }
            &.buttons {
                display: flex;

                @media screen and (max-width: 487px) {
                    justify-content: space-between;
                }

                .back {
                    margin: 0 10px 0 auto;

                    @media screen and (max-width: 487px) {
                        margin: 0 10px 0 0;
                    }
                }
            }
        }

    }
}

.btn {
    //display: flex;
    //align-items: center;
    //margin: 0 0 0 auto;
    display: inline-block;
    font-size: 14px;
    padding: 10px 40px;
    border: none;

    &.green {
        color: #fff;
        background-color: #8bc34a;
    }
}

.send {
    text-align: right;
    .btn {
        margin: 0 0 0 auto;
    }
}

    
.att-list {

    li {
        
    }
}

.att-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
    li {
        float: left;
        clear: left;
        padding: 10px;
        margin: 5px;
        border-radius: 10px;
        background-color: $rhp-light-gray;

        span:last-of-type {
            display: inline-block;
            padding: 0 4px;
        }
    }

    @include clear-fix;
}

.error {
    input,
    textarea {
        border: 1px solid #AB070B !important;
        background-color: rgba(171, 7, 11, 0.2) !important;
    }

    label, a {
        color: #AB070B;
    }
    .msg {
        display: block;
        font-size: 12px;
        line-height: 2em;
        width: 100%;
        padding: 0;
        text-indent: 3em;
        color: #ffffff;
        background-color: #AB070B;
        background-size: 16px;


    }
}

.content-404 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 0 40px;
    p {
        font-size: 24px;
        line-height: 1.2em;
        margin: 10px 0;
        color: #6c6c6c;

        strong {
            font-size: 80px;
            line-height: 1.2em;
            margin: 10px 0;
            color: #cccccc;
        }
    }
    .ico {
        font-size: 200px;
        line-height: 1em;
        margin: 0.5em 0 10px;
        color: #cccccc;
    }
}

.lds-ring {
    // display: inline-block;
    // position: relative;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-20%, -50%);

    width: 80px;
    height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #7ec34d;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #7ec34d transparent transparent transparent;
      }

      div:nth-child(1) {
        animation-delay: -0.45s;
      }

      div:nth-child(2) {
        animation-delay: -0.3s;
      }

      div:nth-child(3) {
        animation-delay: -0.15s;
      }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  