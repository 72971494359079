/* mixins */
@mixin turn($angle) {
    -webkit-transform: rotate($angle);
        -ms-transform: rotate($angle);
            transform: rotate($angle);
  }
  @mixin usrselet($val) {
      -webkit-user-select: $val;
      -moz-user-select: $val;
      -ms-user-select: $val;
      user-select: $val;
  }
@mixin  clear-fix {
      &:before, &:after{
          content: '';
          display: table;
      }
      &:after {
          clear: both;
      }
  }
  @mixin bg($img, $clr: transparent, $rpt: no-repeat, $lft: center, $tp: center, $bgSz: 18px){
      background-color: $clr;
      background-image: url($img);
      background-repeat: $rpt;
      background-position: $lft $tp;
      background-size: $bgSz;
  }
  @mixin flex-center($align: center, $wrap: wrap, $justify: space-between) {
    display: flex;
    align-items: $align;
    flex-wrap: $wrap;
    justify-content: $justify;
  }
/* ********* */