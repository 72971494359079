// Colours
$rhp-black: #000000;
$rhp-darker-gray: #333333;
$rhp-dark-gray: #666666;
$rhp-gray: #999999;
$rhp-light-gray: #cccccc;
$rhp-semi-lighter-gray: #ebebeb;
$rhp-lighter-gray: #f4f4f4;

$rhp-green: #7ccb45;
$rhp-light-green: #e7efe2;
$rhp-red: #AB070B;
$rhp-light-red: #CA3B3B;
$rhp-white: #ffffff;

$rhp-semi-trn-red: rgba(171, 7, 11, 0.2);
$rhp-semi-trn-black:rgba(0,0,0, 0.85);

// Font weights
$light: 100;
$regular: 400;
$bold: 600;
$bolder: 700;

// Base Font
$base-font-family: 'Muli', Helvetica, Arial, sans-serif;
$base-font-size-extra-larger: 24px;
$base-font-size-larger: 18px;
$base-font-size-larg: 16px;
$base-font-size: 14px;
$base-font-size-small: 12px;

// tool-bar
$tool-bar-height: 42px;
$tool-bar-white: $rhp-white;

// footer-bar
$footer-bar-white: $rhp-white;