.get-app-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.get-social {
    margin: 0 0 0 30px;
}
.social {
    padding: 5px 0;
}
@media screen and (max-width: 528px) {
    .get-app-social {
        flex-wrap: wrap;


    }
    .get-app-social .store,
    .get-app-social .get-social {
        width: 100%;
        margin: 0;	
    }
}


.footer-grey {
    background-color: #575858;
    padding: 40px 20px;
    flex: 1;
    text-align: left;

    .logo img {
        width: 138px;
    }

    .title-header {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .footer-login-wrapper {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .get-app-social {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .store a:last-of-type {
                margin-left: 10px;
            }

            .get-social {
                margin: 0 0 0 30px;

                .social {
                    padding: 5px 0;

                    a {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    .footer-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        font-size: 13px;
        width: 300px;

        .button-support {
            margin: 0 0 50px 0;

            button-support {

            }

            a {
                float: none;

                position: relative;
                float: left;
                display: block;
                font-weight: bold;
                text-align: center;
                line-height: 25px;
                padding: 0 10px 0 35px;
                padding: 0 10px 0 0;
                text-decoration: none;
                color: #FFF;
                background-color: #7ec34d;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;

                img {
                    position: static;
                    display: inline-block;
                    box-sizing: border-box;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    vertical-align: middle;
                }
                span {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 300;
                    vertical-align: middle;
                }
            }
        }
    }

    .footer-all-right {
        text-align: center;
        margin-top: 35px;

        span {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            display: block;

            a {
                color: #3f7398;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 813px) {
    .footer-login-screen.footer-grey {
    }
    .footer-login-screen.footer-grey .footer-login-wrapper {
        flex-wrap: wrap;
        text-align: center;
    }
    .footer-grey  .footer-icons,
    .footer-grey  .footer-nav {
        justify-content: center;
        width: 100%;
    }
    .footer-grey .footer-nav .button-support {
        margin: 20px 21%;
    }
    .footer-grey .footer-nav ul, 
    .footer-grey .footer-nav ul:first-of-type {
        width: 40%;
        margin: 0 10px;
        text-align: left;
    }

}