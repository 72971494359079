@import "./main.scss";

.att-pop {
  .dialog-paper {
      width: 100%;
      height: 100%;
      max-width: 60%;
      margin: 0; 
  }
  header {
      position: absolute;
      width: 100%;
      z-index: 1;

      .close-btn {
          box-sizing: border-box;
          float: right;
          width: 26px;
          height: 26px;
          padding: 6px;
          margin: 10px;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.2);
          a {
              display: block;
              height: 100%;
              
              img {
                  height: 100%;
              }
          }
      }
  }
}