@import "./main.scss";

.post-msg {

    .reply-to,
    .reply-field {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px 10px 0;

        p {
            font-size: 14px;
            line-height: normal;
            padding: 0;
            margin: 0;
            text-align: left;
        }
        .member-info {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: auto;
            padding: 0;
            margin: 10px 0;
        }
        .avator {
            width: 35px;
            height: 35px;
            padding: 0;
            margin: 0 15px 0 0;
            border-radius: 50%;
            overflow: hidden;
        }
        .bubble {
            position: relative;
            flex-grow: 2;
        }
        .date {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: 10px;
            height: auto;
            line-height: 1.5em;
        }
        .name {
            font-weight: bold;
        }

        textarea {
            flex-grow: 2;
            padding: 10px 0;
            font-size: 14px;
            border: none;
            outline: none;
            min-height: 400px;
            background: transparent;
        }
    }


}