@import "./main.scss";

.support {    
    box-sizing: border-box;
    min-height: calc(100vh - 90px - 272px);
    padding: 44px 0;
    background-color: $rhp-lighter-gray;

    p{
        //text-align: center;

        &.greeting {
            font-size: $base-font-size-larg;
            padding: 0;
            margin: 1em 0;
            
        }
        & strong,
        & strong em {
            font-size: $base-font-size-extra-larger;
            font-style: normal;
            line-height: normal;
            color: #666;
        }

    }
    .menu,
    .tc {
        padding: 0;
        margin: 3em 0;

        li {
            min-height: 0;

            a {
                display: block;
                line-height: normal;
                height: auto;
                min-height: 0;
                padding: 0;
                text-align: center;
            }
        }
    }
    .menu {
        li {
            a {
                position: relative;
                font-size: $base-font-size-larg;
                padding: 1em 0;
                .count {
                    display: block;
                    position: absolute;
                    top: -50%;
                    right: -10%;
                    font-size: 12px;
                    padding: 5px 10px;
                    background-color: #CD0000;
                    color: #fff;
                    border-radius: 12px;
            }
            }
        }
    }
    .tc {
        display: flex;
        justify-content: space-around;
        align-items: center;

        li {
            a {
                text-decoration: underline;
            }
        }
    }



}