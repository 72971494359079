@import "./main.scss";

.category {

        .dialog-body {
            /*box-sizing: border-box;
            width: 100%;
            height: 100%;
            max-height: none;
            margin: 0;
            background: none;*/

            .body {
                position: fixed;
                bottom: 0;
                width: 100%;
                max-width: 600px;
                padding: 0;
                background: none;

                transform: translateX(-50%);

                ul {
                    padding: 0;
                    margin: 0 4.1%;
                    border-radius: 5px;
                    background-color: $rhp-white;

                    li {
                        position: relative;
                        display: block;
                        min-height: 48px;
                        padding: 0;
                        border-bottom: 1px solid $rhp-lighter-gray;

                        label {
                            display: block;
                            font-size: $base-font-size;
                            font-weight: bold;
                            line-height: normal;
                            min-height: 0;
                            padding: 1em 0;
                            text-align: center;
                            color: $rhp-dark-gray;
                            //background-image: none;
                            background: url(../images/circle-untick-green.svg) no-repeat 95% center;
                            background-size: 20px;
                        }
                        input[type=radio]:checked ~ label {
                            background-image: url(../images/circle-tick-green.svg);
                        }
                        input[type='radio'] {
                            display: none;
                        }
                    }
                }

                .cancel {
                    margin: 0.8em 4.1% 1.5em;
                    border-radius: 5px;
                    .white.btn {
                        display: block;
                        font-size: $base-font-size;
                        font-weight: bold;
                        line-height: 1em;
                        width: 100%;
                        color: $rhp-dark-gray;
                        background-color: $rhp-white;
                    }
                }

            }
        }
}