@import "./main.scss";


.contact {
    max-width: 800px;
    margin: 0 auto;

    form {
        margin: 0 20px;

        fieldset {
            a {
                text-decoration: none;
            }
        }
    }

}

.att-pop {
    .content {
        .body {
            width: 100%;
            height: 100%;
            background-color: $rhp-darker-gray;
            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                //height: 100%;
                margin: 0;

                img {
                    width: 100%;
                    //height: 100%;
                }
            }
        }
    }
}