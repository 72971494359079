.header {
    background: #FFF;
    text-align: left;
    line-height: 0;
    position: relative;
    width: 100%;
    height: 90px;
}
.header-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 946px;
    margin: 0 auto;
}

.main-logo {
    display: block;
    position: absolute;
    width: 185px;
    /* height: 41px; */
    top: 34px;
    left: 50%;
    margin: auto;

    transform: translateX(-50%);
    text-align: center;

    /* background-image: url(../image/logos/RHP-LogoFlatpie.png); */
    background-size: contain;
    background-repeat: no-repeat;

    z-index: 2;

    a {
        display: inline-block;
        /* width: 185px; */
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.header-slogan {
    width: 175px;
    height: 29px;
    opacity: 1;
    position: absolute;
    top: 42px;
    right: 70px;

    img {
        width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .header {
        height: 60px;
    }
    .header-container {
        margin: 0 auto;
    }
    .main-logo {
        top: 12px;
        left: 0;
        margin-left: 0;
        width: auto;
        transform: none;
        a {
            width: 145px;
        }
    }
    .header-slogan {
        opacity: 0;
        top: -10px;
    }
}
@media (max-width: 768px){
    .main-logo {
        left: 50%;
        transform: translateX(-50%);
    }
}