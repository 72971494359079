@import "./main.scss";

.list-container {
    ul {
        padding: 0;
        margin: 10px 0 70px;
        overflow: visible;

        li {
            min-height: 0;
            margin: 10px 0;
            overflow: visible;
            display: flex;

            .avator {
                flex-grow: 0;
                flex-shrink: 0;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin: 0 10px 0 0;
                overflow: hidden;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .details {
                //float: left;
                flex-grow: 1;

                .bubble {
                    position: relative;
                    padding: 6px 10px 8px 10px;
                    border-radius: 10px;
                    background-color: #ebedf0;
                    //background-color: #fff;

                    p {
                        padding: 0;
                        margin: 0;
                        text-align: left;
                        background-color: transparent;
                    }

                    .name {
                        line-height: normal;
                        height: 18px;
                        padding: 0;
                        margin: 0;
                        text-align: left;

                    }

                    .date {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 10px;
                        height: auto;
                        line-height: 1.5em;
                        margin: 0;
                    }
                    .comment {
                        word-break: break-word;
                        margin: 5px 0 0;
                    }

                }

                .att-list {
                    li {
                        font-size: 10px;
                        margin: 5px 2px;

                        .attachment {
                            cursor: pointer;
                        }
                    }
                }
            }

            @include clear-fix;
        
            &.user {
                .bubble {
                    background-color: #bae3ff;
                }
            }
        }

    }
}

.text-input {
    .field {
        //padding: 0 10px;
    }
}

textarea {
    box-sizing: border-box;
    font: inherit;
    font-size: 16px;
    width: 100%;
    //height: 200px;
    padding: 10px;
    border-radius: 0;
}